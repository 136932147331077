import { difference, keyBy, mapValues, uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

export const NUMERIC_OPERATORS = ['+', '-', '*', '/', 'Min', 'Max']

export const STRING_OPERATIONS = ['Concat'] // ideas: Trim, Uppercase, Lowercase

function toNumber(v) {
  if (typeof v !== 'string') return Number(v)
  const m = v.trim().match(/^(\d+)[ -](\d+)\/(\d+)$/)
  if (m) {
    const wholePart = parseInt(m[1], 10)
    const numerator = parseInt(m[2], 10)
    const denominator = parseInt(m[3], 10)
    return wholePart + numerator / denominator
  } else {
    return parseFloat(v)
  }
}

/**
 * Evaluates a numeric operation.  Note that if ANY of the options are undefined, this
 * will return undefined: this is the safest option (consider for example an area calculation:
 * if length is set but width is not, is it really best just to return length?).
 *
 * Any non-numeric operand (other than undefined) will be converted to a numeric value.  If any
 * operand is unable to be converted to a finite numeric value, undefined will be returned and
 * an error will be logged to the console.
 */
function numericOperation(operands, op) {
  // attempt numeric conversion (filtering out all undefined values); note that
  // this may result in an array of 0 or 1 operands, which are handled differently
  // according to the operation being performed
  const values = operands.filter((v) => v !== undefined).map(toNumber)
  if (values.length === 0) return undefined
  if (!values.every(Number.isFinite)) {
    console.error(
      `attempt to perform "${op}" on non-numeric values: ${JSON.stringify(
        operands
      )}`
    )
    return undefined
  }
  switch (op) {
    case '+':
      // if there are no operands, addition returns 0
      return values.reduce((a, v) => a + v, 0)
    case '-':
      // for subtraction, there must be at least one operand
      if (operands.length === 0) return undefined
      return values.reduce((a, v) => a - v)
    case '*':
      // for multiplication, there must be at least one operand
      if (operands.length === 0) return undefined
      return values.reduce((a, v) => a * v)
    case '/':
      // for division, there must be at least one operand
      if (operands.length === 0) return undefined
      return values.reduce((a, v) => a / v)
    case 'Min':
      // for Min, there must be at least one operand
      if (operands.length === 0) return undefined
      return Math.min(...values)
    case 'Max':
      // for Max, there must be at least one operand
      if (operands.length === 0) return undefined
      return Math.max(...values)
    default:
      throw new Error(`unrecognized operation: ${op}`)
  }
}

/**
 * Evaluates a string operation.  Any undefined values are filtered out, and then
 * all other values are converted to strings before processing the operation.
 */
function stringOperation(inputValues, operator) {
  const values = inputValues.filter((v) => v !== undefined).map(String)
  switch (operator) {
    case 'Concat':
      return values.join('')
    default:
      throw new Error(`unrecognized operation: ${operator}`)
  }
}

/**
 * Given a computed node value expression, and a map of all nodes, will resolve the expression
 * to a concrete value.
 */
export function getComputedValue(value, nodesByKey) {
  switch (value.type) {
    case 'Formula':
      if (NUMERIC_OPERATORS.includes(value.operator)) {
        const operands = value.operands.map((v) =>
          getComputedValue(v, nodesByKey)
        )
        return numericOperation(operands, value.operator)
      }
      if (STRING_OPERATIONS.includes(value.operator)) {
        const operands = value.operands.map((v) =>
          getComputedValue(v, nodesByKey)
        )
        return stringOperation(operands, value.operator)
      }
      throw new Error(`unrecognized operator: ${value.operator}`)
    case 'Literal':
      return value.value
    case 'Reference':
      if (!nodesByKey[value.key]) {
        console.error(`invalid reference key:`, value.key)
        return undefined
      }
      return nodesByKey[value.key].resolvedValue
    case 'Switch':
      const node = nodesByKey[value.switchOn.key]
      const key = node?.resolvedValue
      return key === undefined
        ? undefined
        : value.case[String(key)] ?? undefined
    default:
      throw new Error('invalid ComputedNodeValue: ' + JSON.stringify(value))
  }
}

/**
 * Given a collection of options and a filter, return a collection of options with the filter
 * applied.  Note: this ignores the filter's "if" clause: it's up to the caller to determine
 * if this filter should be applied.  This simply applies the actions.
 *
 * Duplicate options will be removed.
 */
function processComputedOptionFilter(options, filter) {
  let res = [...options]
  if (filter.remove) res = difference(res, filter.remove)
  if (filter.add) res = res.concat(filter.add)
  // note that "set" will override "remove" and "add"
  if (filter.set) res = filter.set
  return uniq(res)
}

/**
 * Given an IfExpression and a collection of input nodes, evaluates the expression and
 * returns `true` or `false`
 */
function evaluateIfExpression(ifExpression, nodesByKey) {
  const op1 = getComputedValue(ifExpression[0], nodesByKey)
  const op2 = getComputedValue(ifExpression[2], nodesByKey)

  switch (ifExpression[1]) {
    case '<':
      return op1 !== undefined && op2 !== undefined && op1 < op2
    case '<=':
      return op1 !== undefined && op2 !== undefined && op1 <= op2
    case '==':
      // eslint-disable-next-line eqeqeq
      return op1 == op2
    case '>':
      return op1 !== undefined && op2 !== undefined && op1 > op2
    case '>=':
      return op1 !== undefined && op2 !== undefined && op1 >= op2
    default:
      return false
  }
}

/**
 * Each field on a smart form is an "input node"; the input nodes form a dependency
 * graph which determines how the nodes interact.  For example, field values may be
 * computed from the values of other fields, and the options available to select fields
 * may be switched based on the values of other fields.
 */
class InputNode {
  dependsOn = []
  dependedOnBy = []
  config = undefined
  nodesByKey = undefined
  constructor(config, nodesByKey) {
    this.config = config
    this.nodesByKey = nodesByKey
    nodesByKey[this.key] = this
  }
  get key() {
    return this.config.key
  }
  #resolvedValue = undefined
  get resolvedValue() {
    const { value, options } = this.config
    // if this is a select field, 'options' will be populated and...
    if (options) {
      // we need to check that the resolved value is valid (i.e. in the list of resolved options)
      const resolvedValue = this.#resolvedValue
      if (resolvedValue === undefined) return undefined
      return this.resolvedOptions?.includes(String(resolvedValue))
        ? this.#resolvedValue
        : undefined
    }
    return value
      ? getComputedValue(value, this.nodesByKey)
      : this.#resolvedValue
  }
  set resolvedValue(value) {
    if (this.config.value)
      throw new Error(
        `attempt to set value for node ${this.key}, which has a computed value`
      )
    this.#resolvedValue = value
  }

  /**
   * Get value formatted for display.  Primarily, this applies any number
   * formatting to numeric values, and converts everything else to a string.
   */
  get formattedValue() {
    const { config } = this
    const v = this.resolvedValue
    if (v === undefined) return ''
    return typeof v === 'number' && config.numberFormat
      ? v.toLocaleString(undefined, config.numberFormat)
      : String(v)
  }

  reset() {
    if (!this.config.value) this.#resolvedValue = undefined
  }

  get hidden() {
    const { hideIf, showIf } = this.config
    if (hideIf && evaluateIfExpression(hideIf, this.nodesByKey)) return true
    if (showIf && !evaluateIfExpression(showIf, this.nodesByKey)) return true
    return false
  }

  get resolvedOptions() {
    const {
      key,
      config: { computedOptions },
      nodesByKey,
    } = this
    let options = this.config.options
    if (computedOptions?.switch) {
      const { on, case: switchCase } = computedOptions.switch
      const switchOnValue = getComputedValue(on, nodesByKey)
      if (switchOnValue === undefined) {
        options = computedOptions.default || []
      } else {
        if (typeof switchOnValue !== 'string')
          throw new Error(
            `${key}: invalid option switch on ${JSON.stringify(
              on
            )}; must be string`
          )
        options = switchCase[switchOnValue] || computedOptions.default || []
      }
    }
    for (const filter of computedOptions?.filters || []) {
      // if filters are provided, but options aren't specified, we assume an empty set of options
      if (options === undefined) options = []
      if (evaluateIfExpression(filter.if, nodesByKey))
        options = processComputedOptionFilter(options, filter)
    }
    return options
  }
}

/**
 * Given a (possibly undefined) form input value, returns keys of all dependencies of this
 * value.  Note that there may be duplicate keys in the return value.
 */
function getValueDeps(value) {
  if (!value) return []
  const deps = []
  switch (value.type) {
    case 'Reference':
      deps.push(value.key)
      break
    case 'Formula':
      deps.push(...value.operands.map(getValueDeps).flat())
      break
    default:
      break
  }
  return deps
}

function getOptionDeps(options) {
  if (!options) return []
  const deps = []
  if (options.switch?.on.type === 'Reference') deps.push(options.switch.on.key)
  for (const filter of options.filters || []) {
    if (filter.if[0].type === 'Reference') deps.push(filter.if[0].key)
    if (filter.if[2].type === 'Reference') deps.push(filter.if[2].key)
  }
  return deps
}

function getDeps(config) {
  return uniq([
    ...getOptionDeps(config.computedOptions),
    ...getValueDeps(config.value),
  ])
}

export function buildInputNodeGraph(configs) {
  const byKey = {}
  const all = Object.values(configs).map(
    (config) => new InputNode(config, byKey)
  )
  // add dependencies
  for (const node of all)
    node.dependsOn = getDeps(node.config).map((key) => byKey[key])
  // add reverse dependencies (dependedOnBy)
  for (const node of all)
    for (const dep of node.dependsOn) dep.dependedOnBy.push(node)
  const roots = all.filter((node) => node.dependsOn.length === 0)
  return { all, roots, byKey }
}

function getFormErrors(configsByKey, valuesByKey, hiddenByKey) {
  const byKey = mapValues(configsByKey, (config, key) => {
    if (!config.rules || hiddenByKey.has(key)) return undefined
    const errors = []
    for (const rule of config.rules) {
      switch (rule) {
        case 'required': {
          const v = valuesByKey[key]
          if (v === undefined || (typeof v === 'string' && v.trim() === ''))
            errors.push('Required field.')
          break
        }
        default:
          throw new Error(`unrecognized rule: ${rule}`)
      }
    }
    return errors.length === 0 ? undefined : errors
  })
  return {
    byKey,
    all: Object.entries(byKey)
      .map(([key, errors]) => ({ key, errors: errors || [] }))
      .filter((o) => o.errors.length > 0),
  }
}

/**
 * React hook to use smart form; provide form input configs, and form state
 * and update functions are returned.
 *
 * NOTE: a change to _any_ field results in a a React update to _all_ fields, which may
 * lead to poor performance on forms with many fields.  Switching to a proxy-based state
 * system (like [Valtio](https://github.com/pmndrs/valtio)) for this component can
 * address this.
 */
export function useSmartform(configs) {
  const keys = useMemo(() => configs.map((config) => config.key), [configs])
  const configsByKey = useMemo(() => keyBy(configs, 'key'), [configs])
  const { all, byKey } = useMemo(() => {
    return buildInputNodeGraph(configs)
  }, [configs])
  const buildState = useCallback(() => {
    const valuesByKey = mapValues(
      keyBy(
        // only legitimate input types (input, select) have values; this will
        // filter out images, labels, etc.
        keys
          .map((k) => byKey[k])
          .filter((elt) =>
            ['input', 'textarea', 'select', 'checkbox'].includes(
              elt.config.type
            )
          ),
        'key'
      ),
      (elt) => elt.formattedValue
    )
    const hiddenByKey = new Set(keys.filter((key) => byKey[key].hidden))
    const nextState = {
      keys,
      configsByKey,
      valuesByKey,
      optionsByKey: mapValues(byKey, (node) => node.resolvedOptions),
      hiddenByKey,
      errors: getFormErrors(configsByKey, valuesByKey, hiddenByKey),
    }
    return nextState
  }, [byKey, configsByKey, keys])
  const [state, setState] = useState(buildState())
  const updateField = useCallback(
    // note: "name" = "key" (using "name" to support existing code)
    ({ name: name, value }) => {
      byKey[name].resolvedValue = value
      setState(buildState())
    },
    [buildState, byKey]
  )
  const reset = useCallback(() => {
    for (const node of all) node.reset()
    setState(buildState())
  }, [all, buildState])
  return {
    state,
    updateField,
    reset,
  }
}
