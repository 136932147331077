import PropTypes from 'prop-types'
import React from 'react'
import { DropTarget } from 'react-dnd'
import {
  DND_SLIDE,
  dropCollect,
  slideContainerTarget,
} from '../../../dux/modules/DragDrop'
import { scrollElement } from '../../../dux/modules/utils'
import DraggableSlide from './DraggableSlide'

class SlideDeck extends React.Component {
  handleOnAddSlide = () => {
    // Show the select asset modal
    this.props.builderActions.createSlideForBuilder(
      this.props.builderType,
      null
    )
  }

  makeSlideActive = (slideId) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      activeSlide: slideId,
      activeScreen: `${this.props.builderType}EditSlide`,
    })
  }

  handleOnClickSlide = (slideId) => {
    if (this.lastSlideNull()) return
    this.makeSlideActive(slideId)
  }

  scroll = (slideId) => {
    const slideEl = document.getElementById(slideId)
    const parentEl = document.getElementById('slide-container')
    if (slideEl && parentEl) {
      const parentRect = parentEl.getBoundingClientRect()
      const slideRect = slideEl.getBoundingClientRect()
      if (slideRect.right > parentRect.right) {
        scrollElement(
          {},
          parentEl,
          parentEl.scrollLeft,
          slideRect.right - parentRect.right + 12,
          350
        )
        //parentEl.scrollLeft = (slideRect.right - parentRect.right + parentEl.scrollLeft)
      }
      if (slideRect.left < parentRect.left) {
        scrollElement(
          {},
          parentEl,
          parentEl.scrollLeft,
          -(parentRect.left - slideRect.left + 8),
          350
        )
        //parentEl.scrollLeft = parentEl.scrollLeft - (parentRect.left - slideRect.left + 8)
      }
    }
  }

  indexSlide = (step) => {
    const { builderData, activeSlide } = this.props
    if (this.lastSlideNull() || builderData.slides.length === 0) return
    // Check slide position relative to container and update container scroll position if necessary
    const currentIndex = this.getIndexOfSlide(activeSlide)
    this.scroll(builderData.slides[currentIndex + step].id)
    this.makeSlideActive(builderData.slides[currentIndex + step])
  }

  handleClickLeft = () => {
    if (!this.props.activeSlide || this.firstSlideIsActive()) return
    this.indexSlide(-1)
  }

  handleClickRight = () => {
    if (!this.props.activeSlide || this.lastSlideIsActive()) return
    this.indexSlide(1)
  }

  firstSlideIsActive = () => {
    const { builderData, activeSlide } = this.props
    if (builderData.slides.length === 0) return false

    const firstSlideId = builderData.slides[0].id
    return activeSlide === firstSlideId
  }

  lastSlideIsActive = () => {
    const { builderData, activeSlide } = this.props
    if (builderData.slides.length === 0) return false

    const lastSlideId = builderData.slides[builderData.slides.length - 1].id
    return activeSlide === lastSlideId
  }

  lastSlideNull = () => {
    if (this.props.builderData.slides.length == 0) return false
    const lastSlide =
      this.props.builderData.slides[this.props.builderData.slides.length - 1]
    return !this.props.allAssets[lastSlide.mainImage]
  }

  /* Add slide button is only active afer meta has been added */
  isAddDisabled = () => {
    return (
      this.lastSlideNull() ||
      this.props.builderData.activeScreen === 'addSlide' ||
      !this.props.builderData.title ||
      !this.props.builderData.targetSaveFolder
    )
  }
  getIndexOfSlide = (id) => {
    return this.props.builderData.slides.findIndex((slide) => slide.id === id)
  }

  isSlideActive = (id) => this.props.activeSlide === id

  componentDidUpdate(prevProps) {
    if (this.props.activeSlide !== prevProps.activeSlide)
      this.scroll(this.props.activeSlide)
  }

  render() {
    // If a null slide exists, then the USER must select a background image
    // or delete the slide before doing anything else.
    const lastSlideNull = this.lastSlideNull()
    // Choose what function is run when a slide is dragged and dropped.
    const reorderSlides = lastSlideNull
      ? () => {}
      : this.props.builderActions.reorderSlides

    return (
      <div className="builders__slidedeck">
        <div
          className={`builders__slidedeck-arrow-left ${
            this.props.activeSlide ? '' : 'builders__slidedeck-arrow-disabled'
          }`}
          onClick={this.handleClickLeft}
        >
          <i className="fa fa-arrow-circle-left ${this.props.activeSlide" />
        </div>
        <div className="builders__slidedeck-wrapper">
          {this.props.connectDropTarget(
            <div className="builders__slidedeck-container" id="slide-container">
              {this.props.slides.map((slide, index) => {
                return (
                  <DraggableSlide
                    key={slide.id}
                    index={index}
                    slide={slide}
                    mainImage={
                      slide.mainImage &&
                      this.props.allAssets[slide.mainImage] &&
                      this.props.allAssets[slide.mainImage].preview
                    }
                    builderType={this.props.builderType}
                    reorderSlides={reorderSlides}
                    isActive={this.isSlideActive(slide.id)}
                    makeSlideActive={() => this.handleOnClickSlide(slide)}
                  />
                )
              })}
            </div>
          )}
          <div>
            <AddSlide
              key="addSlide"
              onAdd={this.handleOnAddSlide}
              disabled={this.isAddDisabled()}
            />
          </div>
        </div>
        <div
          className={`builders__slidedeck-arrow-right ${
            this.props.activeSlide ? '' : 'builders__slidedeck-arrow-disabled'
          }`}
          onClick={this.handleClickRight}
        >
          <i className="fa fa-arrow-circle-right" />
        </div>
      </div>
    )
  }
}

const AddSlide = (props) => (
  <div
    className={`builders__slide-button${props.disabled ? '-disabled' : ''}`}
    onClick={props.disabled ? () => {} : props.onAdd}
  >
    <i className="fa fa-plus-circle" />
  </div>
)

AddSlide.propTypes = {
  onAdd: PropTypes.func,
  disabled: PropTypes.bool,
}

SlideDeck.propTypes = {
  slides: PropTypes.array,
  builderType: PropTypes.string,
  builderData: PropTypes.object,
  allAssets: PropTypes.object,
  openSelectAssetModal: PropTypes.func,
  connectDropTarget: PropTypes.func,
  builderActions: PropTypes.shape({
    reorderSlides: PropTypes.func,
    createSlideForBuilder: PropTypes.func,
    makeSlideActiveForBuilder: PropTypes.func,
    setScreenForBuilder: PropTypes.func,
    setKeyForBuilder: PropTypes.func,
  }),
}

export default DropTarget(
  DND_SLIDE,
  slideContainerTarget,
  dropCollect
)(SlideDeck)
