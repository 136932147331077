import React, { useState } from 'react'
import Title from 'antd/lib/typography/Title'
import { useSmartform } from './useSmartform'
import { useCallback, useMemo } from 'react'
import '../../../styles/Smartform.css'
import { Button, Checkbox, Input, Select } from 'antd'
import buildEmailBody from './buildEmailBody'

import { Auth } from 'aws-amplify'

import store from '../../../../index'

/**
 * Invokes `notifications/send` API to deliver email using current user credentials.
 *
 * @param {{ to: string[], from: string[], subject: string, textBody: string, htmlBody: string }}
 */
async function sendEmail({ to, cc, from, subject, textBody, htmlBody }) {
  const {
    config: {
      appConfig: { baseUrl },
    },
  } = store.getState()
  const Authorization = (await Auth.currentSession())?.idToken?.jwtToken
  if (!Authorization)
    throw new Error(
      `sendEmail: could not retrieve ID token; is user signed in?`
    )
  const url = `${baseUrl}/api/notifications/send`
  const method = 'POST'
  const res = await fetch(url, {
    method,
    headers: { Authorization },
    body: JSON.stringify({
      to_addresses: to,
      cc_addresses: cc,
      bcc_addresses: ['development@pop-art.com'],
      from_address: from,
      subject,
      textBody,
      htmlBody,
      // I can't find any documentation about what (if anything) the "notify" property
      // does; an examination of the code suggests it doesn't do anything, but a deeper
      // investigation is required to be sure...for now I'm just leaving it (e@optionlab.com)
      notify: true,
    }),
  })
  if (!res.ok)
    throw new Error(`${method} ${url}: ${res.status} ${res.statusText}`)
  return res
}

export function Smartform(props) {
  const { formConfig } = props
  const inputConfigs = useMemo(
    () =>
      formConfig.elements.flatMap((e) =>
        e.type === 'inputSection'
          ? e.sectionData.map((field) => ({
              ...field,
              key: `${e.sectionName}-${field.id}`,
            }))
          : []
      ),
    [formConfig]
  )
  const smartform = useSmartform(inputConfigs)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setIsSubmitting(true)
      const {
        user: { name, email },
      } = store.getState()
      const submittedBy = { name, email }
      const { htmlBody, textBody } = buildEmailBody(
        formConfig,
        smartform.state.valuesByKey,
        submittedBy
      )
      const { submitConfig } = formConfig
      try {
        await sendEmail({
          to: [...submitConfig.actionData.to],
          cc: [submittedBy.email],
          from: submitConfig.actionData.from,
          subject: submitConfig.actionData.subject,
          textBody,
          htmlBody,
        })
        smartform.reset()
      } catch (err) {
        alert(`There was an error submitting; please contact support.`)
      } finally {
        setIsSubmitting(false)
      }
    },
    [smartform, formConfig]
  )

  return (
    <div className="smartform">
      <form onSubmit={onSubmit}>
        {formConfig.elements.map((e) => {
          if (e.type == 'link') {
            const href = `/library/asset/${e.src}`
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '2em',
                }}
                key={JSON.stringify(e)}
              >
                <a target="_blank" href={href}>
                  {e.text}
                </a>
              </div>
            )
          } else if (e.type !== 'inputSection') return undefined
          const elements = e.sectionData.map((elt) => ({
            ...elt,
            key: `${e.sectionName}-${elt.id}`,
          }))
          return (
            <div
              key={e.sectionName}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2em',
              }}
            >
              <Title className="title" level={2}>
                {e.sectionName}
              </Title>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}
              >
                <SmartformElements elements={elements} smartform={smartform} />
              </div>
            </div>
          )
        })}
        <div style={{ marginTop: '2em' }}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting || smartform.state.errors.all.length > 0}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  )
}

function SmartformElements(props) {
  const { elements, smartform } = props
  return elements.map((input) => (
    <SmartformElement key={input.key} input={input} smartform={smartform} />
  ))
}

function SmartformElement(props) {
  const { input, smartform } = props
  const { state, updateField } = smartform
  if (input.key && state.hiddenByKey.has(input.key)) return null
  switch (input.type) {
    case 'checkbox': {
      const checked = !!state.valuesByKey[input.key]
      return (
        <span>
          <Checkbox
            className="checkbox"
            checked={checked}
            onChange={() =>
              updateField({
                name: input.key,
                value: checked ? undefined : true,
              })
            }
          >
            {input.label}&nbsp;
            {input.note && <span className="note">{input.note}</span>}
          </Checkbox>
        </span>
      )
    }
    case 'input': {
      const value = state.valuesByKey[input.key]
        ? String(state.valuesByKey[input.key])
        : ''
      return (
        <label>
          {input.label}
          {input.note && <span className="note">{input.note}</span>}
          <Input
            type={input.dataType}
            placeholder={
              input.rules?.includes('required') ? '(required)' : undefined
            }
            value={value}
            onChange={(e) =>
              updateField({ name: input.key, value: e.target.value })
            }
            addonAfter={input.units}
          />
        </label>
      )
    }
    case 'select': {
      const config = state.configsByKey[input.key]
      const options = state.optionsByKey[input.key] || []
      return (
        <label>
          {input.label}
          {input.note && <span className="note">{input.note}</span>}
          {options.length > 0 ? (
            <Select
              onChange={(e) => updateField({ name: input.key, value: e })}
              value={state.valuesByKey[input.key]}
              placeholder={config.placeholder}
            >
              <Select.Option value="">
                {input.rules?.includes('required') ? '(required)' : undefined}
              </Select.Option>
              {options.map((value) => (
                <Select.Option key={value}>{value}</Select.Option>
              ))}
            </Select>
          ) : (
            <div style={{ color: 'red' }}>
              {config.emptyText || '(no options available)'}
            </div>
          )}
        </label>
      )
    }
    case 'textarea': {
      const value = String(state.valuesByKey[input.key] || '')
      return (
        <label>
          {input.label}
          {input.note && <span className="note">{input.note}</span>}
          <Input.TextArea
            value={value}
            placeholder={
              input.rules?.includes('required') ? '(required)' : undefined
            }
            onChange={(e) =>
              updateField({ name: input.key, value: e.target.value })
            }
          />
        </label>
      )
    }
    case 'label':
      return (
        <div className="lbl">
          <Title className="title" level={4}>
            {input.label}
          </Title>
          {input.note && <span className="note">{input.note}</span>}
        </div>
      )
    case 'link': {
      const href = `/library/asset/${input.src}`
      return <a href={href}>{input.text}</a>
    }
    case 'image':
      return <img src={input.data} />
  }
}
