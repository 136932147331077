import { Button, Checkbox, Form, Input, Radio, Select } from 'antd'
import React, { Fragment } from 'react'
import {
  COMPARISON__BUILDER_TYPE,
  COMPARISON__MAX_DESCRIPTION_LENGTH,
  COMPARISON__MAX_DISCLAIMER_LENGTH,
  COMPARISON__MAX_TITLE_LENGTH,
  FEATURE__BUILDER_TYPE,
  FEATURE__MAX_DESCRIPTION_LENGTH,
  FEATURE__MAX_NUM_RELATED,
  FEATURE__MAX_TITLE_LENGTH,
  NAVIGATOR__MAX_DESCRIPTION_LENGTH,
  NAVIGATOR__MAX_TITLE_LENGTH,
  NEWS__BUILDER_TYPE,
  NEWS__MAX_COPYRIGHT_LENGTH,
  NEWS__MAX_DESCRIPTION_LENGTH,
  NEWS__MAX_TEXT_CONTENT_LENGTH,
  NEWS__MAX_TITLE_LENGTH,
  PRODUCT__MAX_DESCRIPTION_LENGTH,
  PRODUCT__MAX_TITLE_LENGTH,
} from '../../../../dux/constants/builders'
import EditableTagGroup from '../../../common/EditableTagGroup'
import AssetListItem from '../components/AssetListItem'
import FormDivider from '../components/FormDivider'
import '../../../styles/Builders.css'

const FormItem = Form.Item
const Option = Select.Option

class MetadataForm extends React.Component {
  render() {
    return <MetadataFormContent {...this.props} ref={this.props.saveFormRef} />
  }
}

const MetadataFormContent = Form.create({
  onValuesChange(props, changedFields) {
    props.onValuesChange(changedFields)
  },
})((props) => {
  const {
    form,
    nextDisabled,
    nextAction,
    onCancel,
    builderType,
    builderData,
    allAssets,
    saveTagsRef,
    openSelectAssetModal,
    selectTarget,
    removeTarget,
    editTarget,
    actionButtonLabel,
    modalActions,
    getComparisonData,
  } = props
  const { getFieldDecorator } = form

  const slidesAsAssets = builderData.slides.map((s) => allAssets[s.mainImage])
  console.log({ ...props, slidesAsAssets })

  // Validation
  const formValidators = {
    feature: {
      title: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: 'Feature Name is required' },
          {
            max: FEATURE__MAX_TITLE_LENGTH,
            message: `Feature Name has a maximum character limit of ${FEATURE__MAX_TITLE_LENGTH}`,
          },
        ],
      },
      description: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: FEATURE__MAX_DESCRIPTION_LENGTH,
            message:
              'Descriptive Copy has a maximum character limit of ' +
              `${FEATURE__MAX_DESCRIPTION_LENGTH}`,
          },
        ],
      },
    },
    news: {
      title: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: 'Title is required' },
          {
            max: NEWS__MAX_TITLE_LENGTH,
            message:
              `Title has a maximum character limit of ` +
              `${NEWS__MAX_TITLE_LENGTH}`,
          },
        ],
      },
      description: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: NEWS__MAX_DESCRIPTION_LENGTH,
            message:
              `Description has a maximum character limit of ` +
              `${NEWS__MAX_DESCRIPTION_LENGTH}`,
          },
        ],
      },
      contentType: {
        rules: [
          {
            required: true,
            message: 'News content is required',
          },
        ],
      },
      textContent: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: NEWS__MAX_TEXT_CONTENT_LENGTH,
            message:
              'News Content has a maximum character limit of ' +
              `${NEWS__MAX_TEXT_CONTENT_LENGTH}`,
          },
        ],
      },
      copyright: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: NEWS__MAX_COPYRIGHT_LENGTH,
            message:
              `Copyright has a maximum character limit of ` +
              `${NEWS__MAX_COPYRIGHT_LENGTH}`,
          },
        ],
      },
    },
    product: {
      title: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: 'Product Name is required' },
          {
            max: PRODUCT__MAX_TITLE_LENGTH,
            message:
              `Product Name has a maximum character limit of ` +
              `${PRODUCT__MAX_TITLE_LENGTH}`,
          },
        ],
      },
      description: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: PRODUCT__MAX_DESCRIPTION_LENGTH,
            message:
              `Descriptive Copy has a maximum character limit of ` +
              `${PRODUCT__MAX_DESCRIPTION_LENGTH}`,
          },
        ],
      },
    },
    navigator: {
      title: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: 'Navigator Name is required' },
          {
            max: NAVIGATOR__MAX_TITLE_LENGTH,
            message:
              `Product Name has a maximum character limit of ` +
              `${NAVIGATOR__MAX_TITLE_LENGTH}`,
          },
        ],
      },
      description: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: NAVIGATOR__MAX_DESCRIPTION_LENGTH,
            message:
              `Descriptive Copy has a maximum character limit of ` +
              `${NAVIGATOR__MAX_DESCRIPTION_LENGTH}`,
          },
        ],
      },
    },
    comparison: {
      title: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: 'Comparison Name is required' },
          {
            max: COMPARISON__MAX_TITLE_LENGTH,
            message:
              `Product Name has a maximum character limit of ` +
              `${COMPARISON__MAX_TITLE_LENGTH}`,
          },
        ],
      },
      description: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: COMPARISON__MAX_DESCRIPTION_LENGTH,
            message:
              `Descriptive Copy has a maximum character limit of ` +
              `${COMPARISON__MAX_DESCRIPTION_LENGTH}`,
          },
        ],
      },
      disclaimerText: {
        validateTrigger: 'onBlur',
        rules: [
          {
            max: COMPARISON__MAX_DISCLAIMER_LENGTH,
            message:
              `Disclaimer has a maximum character limit of ` +
              `${COMPARISON__MAX_DISCLAIMER_LENGTH}`,
          },
        ],
      },
    },
  }
  const formLabels = {
    feature: {
      name: 'Feature Name',
      description: 'Descriptive Copy',
      leadImage: 'Feature Image',
      relatedAssets: 'Related Assets',
    },
    news: {
      name: 'Title',
      description: 'Description',
      leadImage: 'Lead Image',
      content: 'News Content',
    },
    product: {
      name: 'Product Name',
      description: 'Description',
      leadImage: 'Preview Image',
    },
    navigator: {
      name: 'Navigator Name',
      description: 'Description',
      leadImage: 'Preview Image',
    },
    comparison: {
      name: 'Comparison Name',
      description: 'Description',
      leadImage: 'Preview Image',
      dataUpload: 'Data Upload (.tsv)',
      primaryClassification: 'Primary Classification',
      disclaimer: 'Comparison Disclaimer',
    },
  }
  const leadImage = allAssets[builderData.leadImage]
  const relatedAssets =
    builderData.relatedAssets &&
    builderData.relatedAssets.filter((ra) => !!allAssets[ra.assetId])
  // BUILDER SIDER SCREEN 1 : Meta
  return (
    <div className="builders__sider-form">
      <div className="builders__sider-form-content">
        {/* Title */}
        <FormItem
          label={
            <span className="builders__sider-form-label">
              {formLabels[builderType].name}
            </span>
          }
        >
          {getFieldDecorator('title', {
            ...formValidators[builderType].title,
            initialValue: props.builderData.title,
          })(<Input.TextArea autosize={{ minRows: 1, maxRows: 3 }} />)}
        </FormItem>

        {/* Description */}
        <FormItem
          label={
            <span className="builders__sider-form-label">
              {formLabels[builderType].description}
            </span>
          }
        >
          {getFieldDecorator('description', {
            initialValue: props.builderData.description,
            ...formValidators[builderType].description,
          })(<Input.TextArea autosize={{ minRows: 3, maxRows: 20 }} />)}
        </FormItem>

        {/* Lead Image */}
        <FormItem
          label={
            <span className="builders__sider-form-label">
              {formLabels[builderType].leadImage}
            </span>
          }
        >
          <div className="builders__sider-padded-div">
            This image represents the asset in the library.
          </div>
          {leadImage && leadImage.preview && (
            <img
              className="builders__sider-image"
              src={`${leadImage.preview}_thumb-310x`}
              alt="lead preview"
            />
          )}
          {getFieldDecorator('leadImage', {
            initialValue: props.builderData.leadImage,
            ...formValidators[builderType].leadImage,
          })(
            <Button onClick={() => openSelectAssetModal('leadImage', 'image')}>
              <span>
                <i className="fa fa-plus-circle" /> Image
              </span>
            </Button>
          )}
        </FormItem>

        {
          /* Related Assets */
          builderType === FEATURE__BUILDER_TYPE && (
            <div>
              <FormDivider opacity={0.1} />
              <FormItem
                label={
                  <span className="builders__sider-form-label">
                    {formLabels[builderType].relatedAssets}
                  </span>
                }
                require={true}
              >
                <div className="builders__sider-padded-div">
                  Features may reference up to 3 informational assets, such as
                  whitepapers or schematics.
                </div>
                {/* This is where the related assets will be previewed */}
                {relatedAssets.map((ra) => (
                  <AssetListItem
                    id={ra.assetId}
                    key={ra.id}
                    asset={allAssets[ra.assetId]}
                    displayText={ra.displayText}
                    onClick={() => selectTarget('relatedAsset', ra)}
                    onClickRemove={(id) => removeTarget('relatedAsset', id)}
                    onClickEdit={() => editTarget('relatedAsset', ra)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
                <Button
                  disabled={relatedAssets.length >= FEATURE__MAX_NUM_RELATED}
                  onClick={() => openSelectAssetModal('relatedAsset')}
                >
                  <span>
                    <i className="fa fa-plus-circle" /> Asset
                  </span>
                </Button>
              </FormItem>
            </div>
          )
        }

        {
          /* News Content */
          builderType === NEWS__BUILDER_TYPE && (
            <div>
              <FormDivider opacity={0.1} />
              <FormItem
                label={
                  <span className="builders__form-label">
                    {formLabels[builderType].content}
                  </span>
                }
              >
                {getFieldDecorator('contentType', {
                  initialValue: props.builderData.contentType,
                  ...formValidators[builderType].contentType,
                })(
                  <Radio.Group>
                    <Radio value="text">Text</Radio>
                    <Radio value="pdf">PDF</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              {builderData.contentType === 'text' ? (
                <FormItem>
                  {getFieldDecorator('textContent', {
                    initialValue: props.builderData.textContent,
                    ...formValidators[builderType].textContent,
                  })(<Input.TextArea rows={10} />)}
                </FormItem>
              ) : (
                <FormItem>
                  {builderData.pdfContent && (
                    <object
                      data={`${allAssets[builderData.pdfContent].url}`}
                      type="application/pdf"
                      width="310px"
                      height="310px"
                    >
                      <p>Failure to launch</p>
                    </object>
                  )}
                  <Button
                    onClick={() => openSelectAssetModal('pdfContent', 'pdf')}
                  >
                    <span>
                      <i className="fa fa-plus-circle" /> PDF
                    </span>
                  </Button>
                </FormItem>
              )}
            </div>
          )
        }
        {
          /* Comparison Data */
          builderType === COMPARISON__BUILDER_TYPE && (
            <div id="comparison-meta">
              <FormDivider opacity={0.1} />
              <FormItem
                label={
                  <span className="builders__sider-form-label">
                    {formLabels[builderType].dataUpload}
                  </span>
                }
              >
                <div className="builders__sider-padded-div">
                  Download our .tsv template and enter your own custom data.
                  Once you upload and create your Comparison you will be able to
                  link images to each model.
                </div>
                <Button
                  onClick={() =>
                    modalActions.openModal('UploadModal', {
                      type: 'support',
                      multiple: false,
                      target: 'comparisons',
                      callback: getComparisonData,
                    })
                  }
                  disabled={!builderData.title}
                >
                  <span>
                    <i className="fa fa-plus-circle" /> Custom Data
                  </span>
                </Button>
              </FormItem>
              <FormDivider opacity={0.1} />
              <FormItem
                label={
                  <span className="builders__sider-form-label">
                    {formLabels[builderType].primaryClassification}
                  </span>
                }
              >
                {getFieldDecorator('primaryClassification', {
                  initialValue: builderData.primaryClassification,
                })(
                  <Select
                    key={builderData.primaryClassification}
                    style={{ width: 300 }}
                    getPopupContainer={() =>
                      document.getElementById('comparison-meta')
                    }
                  >
                    <Option key={'default'} value={null}>
                      None selected
                    </Option>
                    {builderData.classifications
                      .filter((option) => option)
                      .map((record) => (
                        <Option key={record} value={record}>
                          {record}
                        </Option>
                      ))}
                  </Select>
                )}
                <div className="builders__sider-padded-div">
                  If you set a primary classification value, the MST will
                  restrict the items available in the left column of the
                  comparison tool to items with matching classification. The
                  right column can show any other item. If this field is left
                  blank then any 2 items in the data sheet can be compared.
                </div>
              </FormItem>
              <FormDivider opacity={0.1} />
              {
                // Form inputs change based on whether custom disclaimers are allowed
                props.builderSettings.comparisons.allowCustomDisclaimer ? (
                  <Fragment>
                    <FormItem
                      label={
                        <span className="builders__sider-form-label">
                          {formLabels[builderType].disclaimer}
                        </span>
                      }
                    >
                      <div className="builders__sider-padded-div">
                        Admins allow custom disclaimers
                      </div>
                      {getFieldDecorator('useDefaultDisclaimer', {
                        initialValue: props.builderSettings.comparisons
                          .allowCustomDisclaimer
                          ? builderData.useDefaultDisclaimer
                          : true,
                        valuePropName: 'checked',
                        onChange: (e) => {
                          if (e.target.checked) {
                            form.setFieldsValue({
                              disclaimerText:
                                props.builderSettings.comparisons
                                  .globalDisclaimer,
                            })
                          }
                        },
                      })(<Checkbox>Use Default Disclaimer</Checkbox>)}
                    </FormItem>
                    <FormItem
                      label={
                        <span className="builders__sider-form-label">
                          {formLabels[builderType].disclaimer}
                        </span>
                      }
                    >
                      {getFieldDecorator('disclaimerText', {
                        initialValue:
                          builderData.disclaimerText ||
                          props.builderSettings.comparisons.globalDisclaimer,
                      })(
                        <Input.TextArea
                          disabled={form.getFieldValue('useDefaultDisclaimer')}
                          autosize={{ minRows: 3, maxRows: 20 }}
                        />
                      )}
                    </FormItem>
                  </Fragment>
                ) : (
                  <FormItem
                    label={
                      <span className="builders__sider-form-label">
                        {formLabels[builderType].disclaimer}
                      </span>
                    }
                  >
                    <div className="builders__sider-padded-div">
                      Admins do not allow custom disclaimers
                    </div>
                    {getFieldDecorator('disclaimerText', {
                      initialValue:
                        props.builderSettings.comparisons.globalDisclaimer,
                    })(
                      <Input.TextArea
                        disabled
                        autosize={{ minRows: 3, maxRows: 20 }}
                      />
                    )}
                  </FormItem>
                )
              }
            </div>
          )
        }
        <FormDivider opacity={0.1} />
        {/* Tags */}
        <FormItem
          label={<span className="builders__sider-form-label">Tags</span>}
        >
          <EditableTagGroup defaultTags={builderData.tags} ref={saveTagsRef} />
        </FormItem>
      </div>
      {/* Buttons */}
      <div className="builders__sider-button-container">
        <span className="builders__button">
          <Button onClick={onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button type="primary" disabled={nextDisabled} onClick={nextAction}>
            {actionButtonLabel}
          </Button>
        </span>
      </div>
    </div>
  )
})

export default MetadataForm
