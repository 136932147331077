import React, { useEffect, useState } from 'react'
import { Smartform } from './Smartform'
const devFormConfig = require('../../../../dev/forms/dev.form.json')

/**
 * @typedef {{ id: string, extension: 'form', filename: string, title: string, url: string }} Asset
 * @param {{ asset: Asset }} props
 */
export function FormPreview(props) {
  const { asset } = props
  const [formConfig, setFormConfig] = useState(undefined)
  useEffect(() => {
    if (asset.title === 'Test Form' && devFormConfig.version) {
      setFormConfig(devFormConfig)
      return
    }
    fetch(asset.url)
      .then((res) => res.json())
      .then((data) => {
        setFormConfig(data)
      })
      .catch((err) => {
        console.log({ err })
      })
  }, [asset])

  if (formConfig === undefined || formConfig.version > 1) {
    return (
      <div style={{ marginTop: 200, height: 800 }}>
        This version of form asset cannot yet be rendered on the web.
      </div>
    )
  }

  return (
    <div style={{ marginTop: '2em' }}>
      <Smartform formConfig={formConfig} />
    </div>
  )
}
