import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

class Slide extends React.Component {
  render() {
    const mainImage = `${this.props.mainImage}_thumb-84x62`

    return (
      <div
        id={this.props.slide.id}
        className={`builders__slide${this.props.draggable ? '-draggable' : ''}`}
        style={{
          opacity: this.props.isDragging ? 0 : 1,
          borderColor: this.props.isActive ? '#1890ff' : '#b5b5b5',
        }}
        onClick={this.props.makeSlideActive}
        ref={this.props.saveSlideRef}
      >
        <Tooltip title={this.props.tooltipText}>
          {this.props.mainImage && (
            <img
              className="builders__slide-img"
              src={mainImage}
              alt="slide preview"
            />
          )}
        </Tooltip>
      </div>
    )
  }
}

Slide.propTypes = {
  saveSlideRef: PropTypes.func,
  tooltipText: PropTypes.string,
  draggable: PropTypes.bool,
  slide: PropTypes.object,
  isDragging: PropTypes.bool,
  isActive: PropTypes.bool,
  makeSlideActive: PropTypes.func,
  mainImage: PropTypes.string,
}

export default Slide
