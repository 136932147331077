export const BUILDER__SIDER_WIDTH = 400
export const BUILDERS_WITH_SLIDES = ['product', 'navigator']

//News
export const NEWS__MAX_TITLE_LENGTH = 100
export const NEWS__MAX_DESCRIPTION_LENGTH = 500
export const NEWS__MAX_TEXT_CONTENT_LENGTH = 5000
export const NEWS__MAX_COPYRIGHT_LENGTH = 500

//Feature
export const FEATURE__MAX_NUM_RELATED = 3
export const FEATURE__MAX_TITLE_LENGTH = 100
export const FEATURE__MAX_DESCRIPTION_LENGTH = 500
export const FEATURE__MAX_DISPLAY_TEXT_LENGTH = 100
//Product
export const PRODUCT__MAX_TITLE_LENGTH = 100
export const PRODUCT__MAX_DESCRIPTION_LENGTH = 500
export const PRODUCT__MAX_HOTSPOTS = 8
export const HOTSPOT__SIZE = 25
export const HOTSPOT__MAX_DISPLAY_TEXT_LENGTH = 50
//Navigator
export const NAVIGATOR__MAX_TITLE_LENGTH = 100
export const NAVIGATOR__MAX_DESCRIPTION_LENGTH = 500
export const NAVIGATOR__MINIMUM_ZONE_SIZE = 32
export const NAVIGATOR__SLIDE_ICON = 'fa fa-film'
export const NAVIGATOR__BACKGROUND = '#1890ff'
export const NAVIGATOR__SLIDE_LABEL_LENGTH = 50
//Comparison
export const COMPARISON__MAX_TITLE_LENGTH = 100
export const COMPARISON__MAX_DESCRIPTION_LENGTH = 500
export const COMPARISON__MAX_DISCLAIMER_LENGTH = 500

//Types
export const NEWS__BUILDER_TYPE = 'news'
export const FEATURE__BUILDER_TYPE = 'feature'
export const PRODUCT__BUILDER_TYPE = 'product'
export const NAVIGATOR__BUILDER_TYPE = 'navigator'
export const COMPARISON__BUILDER_TYPE = 'comparison'
export const CALCULATOR__BUILDER_TYPE = 'calculator'
export const FORM__BUILDER_TYPE = 'form'
export const BUILDER_TYPES = [
  NEWS__BUILDER_TYPE,
  FEATURE__BUILDER_TYPE,
  PRODUCT__BUILDER_TYPE,
  NAVIGATOR__BUILDER_TYPE,
  COMPARISON__BUILDER_TYPE,
  CALCULATOR__BUILDER_TYPE,
  FORM__BUILDER_TYPE,
]
